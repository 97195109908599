$header-height-desktop: 100px;
$header-height-mobile: 100px;

.header {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 25px;
    height: $header-height-desktop;
    gap: 6px;
    position: sticky;
    z-index: 100;

    div.popup {
        position: absolute;
        top: $header-height-desktop;
        right: 66px;
        background-color: white;
        border: 1px solid #ccc;
        z-index: 100;
        border-radius: 10px;

        @media (max-width: 1024px) {
            right: 44px;
        }

        @media (max-width: 576px) {
            top: 70px;
            right: 26px;
        }

        .popup-sidebar {
            display: none;
            flex-direction: column;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid black;

            @media (max-width: 576px) {
                display: flex;
                max-height: 75vh;
                overflow-y: auto;
            }
        }

        .popup-sidebar::-webkit-scrollbar {
            width: 6px;
        }

        .popup-sidebar::-webkit-scrollbar-track {
            background: transparent !important;
            width: 6px;
        }

        .popup-sidebar::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: black;
        }

        .popup-item {
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 5px;
            cursor: pointer;

            p {
                margin-right: 10px;
                margin-bottom: 0;
            }

            svg {
                widows: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:hover,
            &:focus {
                background-color: #3b83cf;

                svg {
                    color: white;
                }

                p {
                    color: white;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column-reverse;
        height: 100px;
        gap: clamp(0.75rem, -1.563vw + 1.063rem, 0.5rem);

        .input-wrapper[data-closeim] {
            width: 100%;
        }

        .user-wrapper {
            width: 100%;

            .user__content {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    svg {
        width: 30px;
        height: 30px;
    }

    & .input-wrapper {
        width: 70%;
        position: relative;
        margin: 10px 0;

        @media (max-width: 576px) {
            width: 100%;
        }

        .input-container {
            input {
                border: 1px solid #bbb8b8;
                border-radius: 40px;
                padding: 5px 10px;
                background-color: transparent;
                outline: none;
                width: 100%;
                padding: 10px 20px;
                font-size: clamp(0.875rem, 0.781vw + 0.719rem, 1rem);
            }

            .input-options-wrapper {
                text-align-last: center;

                position: absolute;

                width: 100%;

                background-color: white;
                border: 1px solid rgb(222, 226, 230);
                border-radius: 10px;

                padding: 0.3rem 1rem;
                margin-top: 0.7rem;
                transition: all 0.3s ease-in-out;

                z-index: 1;

                max-height: 10.8rem;
                overflow-y: auto;

                .files-list-item-wrapper {
                    display: flex;
                    justify-content: space-between;

                    font-size: 15px;
                    width: 100%;

                    border-bottom: 1px solid rgb(222, 226, 230);

                    padding: 3px 5px;

                    cursor: pointer;
                    transition: all 0.1s ease-in-out;

                    white-space: nowrap;

                    .files-list-item-name {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 78%;
                    }

                    &:hover {
                        background-color: rgb(222, 226, 230);

                        .files-list-item-wrapper {
                            border-bottom: 1px solid white;
                        }
                    }
                }

                .files-list-item-wrapper:last-child {
                    border-bottom: 0;
                }
            }
        }

        svg {
            width: clamp(1.25rem, 3.906vw + 0.469rem, 1.875rem);
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .user__logo-desktop {
        display: block;
        max-height: 80px;
        width: auto;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    .user-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;

        .user__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        svg {
            cursor: pointer;
        }

        .user__logo-mobile {
            display: none;

            @media (max-width: 1024px) {
                display: block;
                width: auto;
                max-height: 60px;
                // max-width: clamp(2.5rem, 10vw + 1rem, 100%);
            }
        }

        @media (max-width: 576px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .user__data-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .user__text {
                display: none;

                @media (min-width: 768px) {
                    display: block;
                }
            }

            &>div {
                position: relative;

                span {
                    font-weight: bold;
                    height: min-content;
                    white-space: nowrap;
                }

                p {
                    font-size: 11px;
                    color: #bbb8b8;
                    font-weight: bold;
                    margin: 0;
                    position: absolute;
                    top: 0;
                    margin-top: clamp(0.75rem, 0.568vw + 0.636rem, 1.063rem);
                }
            }
        }

        #profileP-container {
            width: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
            height: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
            border: solid rgb(96, 132, 190) 3px;
            background-color: white;
            padding: 1px;

            img {
                border-radius: 50%;
            }

            pointer-events: none;

            @media (max-width: 1024px) {
                cursor: pointer;
                pointer-events: all;
            }
        }
    }
}

.layout {
    display: flex;

    ul {
        list-style-type: none;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .main-wrapper {
        overflow: auto;
        min-height: calc(100vh - $header-height-desktop);
        max-height: calc(100vh - $header-height-desktop);

        @media (max-width: 1024px) {
            min-height: calc(100vh - 100px);
            max-height: calc(100vh - 100px);
        }

        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .content-wrapper {
        width: 100%;
        background-color: rgb(244, 245, 247);
        padding: 10px 20px;

        .children-container {
            height: 100%;
            padding: 10px;

            .leaflet-container {
                z-index: 10;
            }

            #f-main {
                background-color: transparent;
                justify-content: center;

                &>div:nth-child(1) {
                    flex-direction: row;
                }

                .col {
                    justify-content: center;
                    max-width: 100%;
                    margin-left: 10%;

                    a {
                        margin-right: 10px;
                    }
                }
            }

        }

        @media (max-width: 576px) {
            padding: 0;
        }
    }

    .sidebar {
        width: 25%;
        max-width: 220px;
        position: relative;
        background-color: rgba(22, 22, 22, 1);
        height: calc(100vh - $header-height-desktop);
        text-align: center;
        padding: 20px 0 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 1024px) {
            height: calc(100vh - 100px);
        }

        @media (max-width: 768px) {
            justify-content: space-between;
        }

        &__logo {
            width: 100%;
            max-width: clamp(6.25rem, 13.889vw + 2.083rem, 12.5rem);

            @media (max-width: 768px) {
                display: none;
            }
        }

        &__logo-no-text {
            display: none;
            max-width: clamp(3.75rem, 15.625vw - 1.875rem, 5.625rem);

            @media (max-width: 768px) {
                display: block;
                margin: 0 auto;
            }
        }

        &__logo-desc {
            font-size: clamp(0.875rem, 1.042vw + 0.5rem, 1rem);
            margin-top: clamp(0.5rem, 6vw - 1.63rem, 1.25rem);
            color: gray;
        }

        p {
            color: white;
        }

        a {
            display: block;
            width: 100%;
        }

        ul {
            text-align: left;
            padding: 0;
            margin-left: 10px;

            .sidebar-subitem {

                @media (min-width: 768px) {
                    margin-left: 1rem;
                }

                svg {
                    @media (max-width: 576px) {
                        display: none;
                    }

                    @media (min-width: 768px) {
                        display: none;
                    }
                }
            }

            li {
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                margin-bottom: 5px;

                @media (max-width: 768px) {
                    max-width: 46px;
                    height: 100%;
                    max-height: 40px;
                    margin: 24px auto;
                    padding: 6px 0;
                    border-radius: 10px;
                }

                &.active,
                &:hover,
                &:focus {
                    background-color: white;

                    span {
                        color: black;
                    }

                    svg {
                        color: black;
                    }
                }

                span {
                    margin-left: clamp(0.625rem, 1.389vw + 0.208rem, 1.25rem);
                    font-size: 14px;
                    color: white;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                svg {
                    color: white;
                    width: 25px;
                    height: 25px;
                    margin-left: 10px;
                }
            }
        }

        .sign-out {
            bottom: 0;
            text-align: center;
            margin: 0 auto;
            cursor: pointer;
            border-radius: 10px;
            padding: 10px 10px 0 10px;

            &:hover,
            &:focus {
                background-color: white;

                svg {
                    color: black;
                }

                p {
                    color: black;
                }
            }

            svg {
                color: white;
                width: 30px;
                height: 30px;
            }

            p {
                font-size: 12px;
                color: white;
                margin-bottom: 10px;
            }
        }

        @media (max-width: 576px) {
            display: none;
        }

        .sidebar__date-time {
            h1 {
                color: white;
            }

            p {
                color: white;
            }
        }

        .navlist li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .navlist::-webkit-scrollbar {
            width: 6px;
        }

        .navlist::-webkit-scrollbar-track {
            background: transparent !important;
            width: 6px;
        }

        .navlist::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: white;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        min-height: 60px;
        background-color: rgb(244, 245, 247);

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    #file-list {
        padding-right: 10px;

        .file_list_info_icon {
            width: 1.4rem;
            margin-left: 1rem;

            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            position: absolute;
            top: 7px;
            right: 1rem;

            cursor: pointer;
        }

        .react-table-lite-row :hover {
            .file_list_info_icon {
                opacity: 1;
            }
        }

    }

    #file-line {
        font-size: clamp(0.875rem, 0.227vw + 0.83rem, 1rem);
        white-space: nowrap;
        margin-bottom: 6px;
        border: none;

        div:nth-child(1) {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        @media (max-width: 768px) {
            grid-template-columns: 7fr 1.5fr 0.5fr;
            grid-template-rows: 1fr;

            div:nth-child(2) {
                display: none;
            }
        }
    }
}