@import "./styles/custom.scss";
@import "./styles/Sidebar.scss";
@import './styles/ProfilePage.scss';
@import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

:root {
    // --break-xs: 475px;
    // --break-sm: 640px;
    // --break-md: 768px;
    // --break-lg: 1024px;
    // --break-xl: 1280px;
    // --break-xxl: 1536px;

    --footer-height: 50px;
}

//elements in forms
.cancelButton {
    margin-left: 1rem;
}

//tables
.right-aligned-cell {
    text-align: right;
}

.fullWidthRow {
    width: 100%;
}

.logo {
    max-width: 48px;
    margin-right: 12px;
}