
#login-view {
    max-height: calc(100dvh - 96px - 40px - 50px);
}

#logo-container {
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
#login-logo {
    width: 12%;
}

#login-container {
    margin: 0 auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#login {
    width: 100%;
}

#alternatives-container {
    margin: 1rem;
    width: 100%;
    justify-content: space-between;
    display: flex;
    position: relative;
}


// register page
#register-view {
    max-height: calc(100dvh - 96px);
    overflow: hidden;
}
#register-container {
    margin: 0 auto;
    width: 60%;
    max-height: calc(100dvh - 96px - 80px - 12vw);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#register {
    width: 100%;
}


// password reset popup
#pwrp-container {
    position: absolute;
    top: 40px;
    right: 0px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
    background-color: #fff;
}

#pwrp-sendBtn {
    width: 80%;
    border: 1px solid #e0e0e0;
    margin: 0px auto 5px;
    transition: background-color 0.3s;
}



@media (max-width: 1024px){
    #login-logo {
        width: 13%;
    }
    #logo-container {
        margin: 25px auto 15px auto;
    }
    #pwrp-container{
        top: 30px;
    }
    #alternatives-container {
        margin: 8px;
    }
}

@media (max-width: 768px){
    #login-logo {
        width: 14%;
    }
}

@media (max-width: 640px){
    #login-logo {
        width: 20%;
    }
    #login > h3 {
        font-size: 20px;
    }
}

@media (max-width: 475px){
    #login-view {
        max-height: calc(100dvh - 120px - 40px);
    }
    #register-view {
        max-height: calc(100dvh - 120px);
    }
    #register-container {
        max-height: calc(100dvh - 120px - 80px - 12vw);
    }
    #login-logo {
        width: 25%;
    }
    #logo-container {
        margin: 30px auto 20px auto;
    }
    #alternatives-container {
        font-size: 15px;
    }

    #pwrp-container {
        font-size: 14px;
        padding: 5px;
        top: 30px;
    }
}
