
#profile-container {
    padding: 0 10px;
}

#profile-main {
    max-height: calc(100dvh - 96px - 50px);
    margin: 50px 75px 0px 75px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#profileP-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid black 3px;
    padding: 0;
    margin-left: 20px;

    position: relative;

    > div:first-child {
        border-radius: 50%;
        overflow: hidden;
    }

    div > img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 1/1;
    }
}
#editPic {
    position: absolute;
    background-color: lightgray;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid black 2px;
    bottom: -6px;
    right: -12px;
    z-index: 2;
    object-fit: cover;
    display: flex;

    font-size: 18px;

    > i {
        margin-left: 9px;
        line-height: 36px;
    }
}
#edit-modal {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    position: absolute;
    left: 125px;
    top: 12px;

    > #pp-file-input {
        grid-row: 1 / span 1;
        grid-column: 1 / span 2;
        width: fit-content;
    }

    > #new-pic-submit {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }

    > #remove-pic {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }

    > Button {
        width: max-content;
    }
}


#profile-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
}
#go-home {
    width: min-content;
    height: min-content;
    align-self: center;
    margin-right: 12px;
}
#profile-save {
    margin-bottom: 18px;
}

#pp-pw-btns {
    display: flex;
    flex-direction: row;
    margin-left: 0px;

    & > Button {
        width: max-content;
        margin-right: 10px;
    }
}

#profile-delete {
    display: flex;
    margin-right: 18px;
    margin-bottom: 5px;
    justify-content: end;

    > Button {
        width: max-content;
        font-size: 18px;
    }
}


@media (max-width: 640px){
    #profile-top-row {
        margin-bottom: 110px;
    }
    #edit-modal {
        top: 115px;
        left: 0px;
    }
}

@media (max-width: 475px){
    #profile-main {
        margin: 50px 40px 0 40px;
    }
}