#f-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 12px;
    background-color: rgb(244, 245, 247);
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;

    > div:nth-child(1){
        max-width: 45%;
        display: flex;
        align-items: center;

        @media (max-width: 640px){
            font-size: 15px;
        }

        @media (max-width: 475px){
            flex-direction: column;
            font-size: 14px;

            > :nth-child(1){
                margin-bottom: 3px;
            }
        }
    }

    > div:nth-child(2){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;

        @media (min-width: 1400px){
            max-width: 35%;
        }

        @media (max-width: 1280px){
            max-width: 40%;
        }

        @media (max-width: 1024px){
            max-width: 45%;
        }

        @media (max-width: 640px){
            max-width: 55%;
        }

        > a {
            color: black;
            text-decoration: none;
            text-align: center;

            :hover {
                text-decoration: underline black;
            }

            @media (max-width: 640px){
                font-size: 14px;
            }

            @media (max-width: 475px){
                font-size: 12px;
            }
        }
    }
}
