.button-direction {
    height: 96px;
    width: 96px;
}

.button-up {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.button-down {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.button-left {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.button-right {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.button-zoom {
    height: 48px;
    width: 96px;
    border-radius: 0px !important;
}

.icon {
    height: 24px;
    width: 24px;
}

.control-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner-control-group {
    display: flex;
    align-items: center;
}

.button-direction:hover, .button-zoom:hover {
    background-color: white !important;
    color: white !important;
    border: 0px !important;
}

.button-direction:hover .icon, .button-zoom:hover .icon {
    color: black;
}